<template>
  <div>
    <b-card class="card-statistics">
      <app-collapse>
        <app-collapse-item title="Filtros">
          <b-form
            v-if="show"
            @submit.prevent
            @reset.prevent
          >
            <b-form-group
              id="input-group-1"
              label="Credores"
              label-for="input-2"
            >
              <v-select
                id="input-2"
                v-model="filtro.credores.selected"
                multiple
                label="title"
                :options="filtro.credores.credOption"
              />
            </b-form-group>
            <div class="d-flex gap">
              <b-form-group
                id="input-group-5"
                label="Data Inicial"
                label-for="input-5"
              >
                <b-form-input
                  id="input-5"
                  v-model="filtro.date.date_initial"
                  locale="pt"
                  type="date"
                  class="mb-1"
                />
              </b-form-group>
              <b-form-group
                id="input-group-7"
                label="Data Final"
                label-for="input-7"
              >
                <b-form-input
                  id="input-7"
                  v-model="filtro.date.date_finaly"
                  locale="pt"
                  type="date"
                  class="mb-2"
                />
              </b-form-group>
              <b-form-group
                id="input-group-3"
                class="w-100"
                label="Tipo de Consulta:"
                label-for="input-3"
              >
                <v-select
                  id="input-3"
                  v-model="filtro.tipo_consulta"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :options="['SHIFT', 'TARGET']"
                />
              </b-form-group>
            </div>
            <b-button
              type="submit"
              variant="primary"
              @click="onSubmit"
            >
              Filtrar
            </b-button>
            <b-button
              type="reset"
              class="ml-2"
              variant="danger"
              @click="onReset"
            >
              Limpar Filtros
            </b-button>
          </b-form>
        </app-collapse-item>
      </app-collapse>
    </b-card>
    <b-overlay
      v-if="showOverlay"
      variant="transparent"
    >
      <b-card>
        <b-col>
          <h3>Relatório de enriquecimento</h3>
        </b-col>
        <b-card
          class="padding-0"
          padding="0"
        >
          <b-col
            class="d-flex mt-2"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filtroBoot"
                debounce="200"
                type="search"
                placeholder="Pesquisar"
              />
            </b-input-group>
            <b-button
              class="ml-2"
              variant="outline-warning"
              @click="exportFile"
            >
              Exportar excel
            </b-button>
          </b-col>
        </b-card>
        <b-table
          small
          striped
          responsive
          :fields="fieldsMainTable"
          :items="enriquecimentos"
          :per-page="itemPerPage"
          :filter="filtroBoot"
          :current-page="currentPage"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(consultas)="data">
            <b-avatar
              :id="`vizualizar-enriquecimento-${data.item.cliente_id}`"
              class="mr-1 cursor-pointer"
              size="32"
              :variant="`light-warning`"
            >
              <feather-icon
                icon="EyeIcon"
                @click="visualizarConsulta(data.item.cliente_id)"
              />
              <b-tooltip
                :target="`vizualizar-enriquecimento-${data.item.cliente_id}`"
                placement="top-right"
              >
                <p class="mb-0">
                  Vizualizar Consulta
                </p>
              </b-tooltip>
            </b-avatar>
          </template>
        </b-table>
      </b-card>
    </b-overlay>
    <b-pagination
      v-model="currentPage"
      :per-page="itemPerPage"
      :total-rows="totalRows"
      hide-footer
      align="center"
      size="sm"
      class="my-0"
    />
    <b-modal
      id="modal-enriquecimento"
      title="Filtros"
      size="xl"
      ok-title="Filtrar"
      hide-footer
    >
      <b-table
        small
        striped
        responsive
        :items="consultas"
        :fildes="fieldsModalTable"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BFormGroup, BForm, BCard, BCol, BOverlay, BButton, BTable, BAvatar, BTooltip, BFormInput, BPagination, BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { saveAs } from 'file-saver'
import axios from '../../../axios-auth'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    vSelect,

    // BOOTSTRAP
    BFormGroup,
    BInputGroup,
    BFormInput,
    BPagination,
    BTable,
    BCard,
    BForm,
    BCol,
    BButton,
    BOverlay,
    BAvatar,
    BTooltip,
  },
  data() {
    return {
      filtro: {
        credores: {
          credOption: [],
          selected: [],
          ids: [],
        },
        tipo_consulta: '',
        date: {
          inicio: '',
          fim: '',
        },
      },
      enriquecimentos: null,
      consultas: [],
      show: true,
      showOverlay: false,
      fieldsModalTable: null,
      fieldsMainTable: [
        { key: 'cliente_id', label: 'cliente id' },
        { key: 'data_consulta', label: 'data da consulta' },
        { key: 'nome_cliente', label: 'nome do cliente' },
        { key: 'tipo_consulta', label: 'tipo da consulta' },
        { key: 'consultas', label: 'Consultas' },
      ],
      filtroBoot: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      itemPerPage: 15,
    }
  },
  watch: {
    'filtro.credores.selected': {
      handler() {
        this.filtro.credores.ids = this.filtro.credores.selected.map(item => item.id)
      },
      deep: true,
    },
  },
  async created() {
    await axios.get('api/v1/credores/')
      .then(res => {
        res.data.dados.map(dt => {
          this.filtro.credores.credOption.push({ title: dt.nome, id: dt.id })
          this.filtro.credores.credOption.sort((a, b) => {
            const nameA = a.title.toLowerCase()
            const nameB = b.title.toLowerCase()
            if (nameA < nameB) return -1
            if (nameA > nameB) return 1
            return 0
          })
        })
      })
  },
  methods: {
    async onSubmit() {
      this.enriquecimentos = await this.pegaEnriquecimentos()

      this.showOverlay = false
      this.$nextTick(() => {
        this.showOverlay = true
      })
    },
    onReset() {
      this.filtro = {
        credores: {
          credOption: [...this.filtro.credores.credOption],
          selected: [],
          ids: [],
        },
        tipo_consulta: '',
        date: {
          inicio: '',
          fim: '',
        },
      }
      this.enriquecimentos = null
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    async pegaEnriquecimentos() {
      const body = {
        credores: this.filtro.credores.ids,
        tipo_consulta: this.filtro.tipo_consulta,
        data_inicial: this.filtro.date.inicio,
        data_final: this.filtro.date.fim,
      }

      const paylod = await axios.post('api/v1/relatorios/backoffice/enriquecimentos', body)
        .then(res => res.data.dados)
      this.totalRows = paylod.length
      return paylod
    },
    flattenObject(obj) {
      let result = {}

      result = Object.entries(obj).reduce((acc, [key, value]) => {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          return { ...acc, ...this.flattenObject(value) }
        }
        return { ...acc, [key]: value }
      }, {})

      return result
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    visualizarConsulta(id) {
      const clienteJson = this.enriquecimentos.find(item => item.cliente_id === id)
      const clienteData = this.flattenObject(JSON.parse(clienteJson.consulta))

      const tableMain = []
      const tableMainFilds = []

      Object.entries(clienteData).forEach(([Coluna, valor]) => {
        if (Array.isArray(valor)) {
          valor = valor.map((objeto, index) => `-- (${index}) -- ${Object.entries(objeto).map(([chv, vlr]) => `${chv}: ${vlr}`).join(', ')}`).join('\n')

          tableMain.push({ Coluna, valor })
        } else {
          tableMain.push({ Coluna, valor })
        }
        tableMainFilds.push(Coluna)
      })

      // eslint-disable-next-line no-underscore-dangle
      clienteData._showDetails = false
      clienteData.isActive = false

      this.consultas = tableMain
      this.fieldsModalTable = tableMainFilds

      this.$bvModal.show('modal-enriquecimento')
    },
    async exportFile() {
      const buffer = await this.generateExcel(this.enriquecimentos)
      const blob = new File([buffer], 'Relatório', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      if (blob) {
        saveAs(blob)
      }
    },
  },
}
</script>
<style>
  .gap {
    gap: 1rem;
  }
</style>
